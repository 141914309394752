import React, { useState,useEffect } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { HOME_URL,SIGNIN_URL } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import USDandFyrow from "../../components/USDandFYrow";
import SearchIcon from "../../components/svg/SearchIcon";
import PlayerListing from "./PlayerListing";
import SectorListing from "./SectorListing";
import { media } from "../../utils/media";

const InternetLandscape = () => {
  const navigate = useNavigate();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const [compSearchString, setCompSearchString] = useState(
    useQuery().get("company") || ""
  );
  const treeziparr = [
    { key: 1, name: "Home" },
    { key: 2, name: "India Internet Landscape" },
  ];

  let handleClickTree = (index) => {
    if (treeziparr[index].key === 1) {
      navigate(HOME_URL);
    }
  };

  useEffect(()=>{
    if(!window.localStorage.getItem("loginStatus"))
      navigate(SIGNIN_URL)
 
   },[])

  return (
    <>
      <PageWrapper>
        <Header />
        <div style={{ backgroundColor: "#FFFFFF", minHeight: "10vh" }}>
          <Header1>
            <LHSTitlediv>
              <div>India Internet Landscape</div>
              <BreadCrumbDiv   
             >
                {treeziparr?.map((obj, i) => (
                  i==0?
                  <BreadCrumbSpan
                    onClick={(e) => {
                      handleClickTree(i, obj.key);
                    }}
                    key={i}
                  >
                    {obj.name} /
                  </BreadCrumbSpan>
                  :
                  <BreadCrumbSpan
                  onClick={(e) => {
                    handleClickTree(i, obj.key);
                  }}
                  key={i}
                >
                  {obj.name} 
                </BreadCrumbSpan>
                ))}
              </BreadCrumbDiv>
            </LHSTitlediv>
            <MobileHeaderDiv>
            <CenterDiv>        
              <CompanyInputWrapper>
                <CompanyInput
                  placeholder="Search sectors and players"
                  type="search"
                  value={compSearchString}
                  onChange={(e) => {
                    setCompSearchString(e.target.value);
                  }}
                ></CompanyInput>
              </CompanyInputWrapper>
            </CenterDiv>
            <RHSTitlediv>
              {/* <USDWrapper>
                <USDandFyrow />
              </USDWrapper> */}
            </RHSTitlediv>
            </MobileHeaderDiv>
          </Header1>
        </div>

        <SectorListing search_sector={compSearchString} />
        <PlayerListing search_player={compSearchString} />
      </PageWrapper>
    </>
  );
};

export default InternetLandscape;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7fcff;
`;

const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  ${media.small`
  flex-direction:column;`}
`;

const LHSTitlediv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 30%;
  font-weight: 500;
  font-size: 30px;
  ${media.small`
    font-size: 20px;
    width:100%;
  `}
`;

const USDWrapper = styled.div`
padding-top:2%;
left:0;
${media.small`
padding: 10px 0.25vw 0px;
  `}
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 10px 3.5vw 0px;
  ${media.small`
  height: 40px;
  padding: 10px 0.25vw 0px;
`}
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
      align-content:center;

  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ${media.small`
    font-size: 9px;
  `}
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
  ${media.small`
  font-size: 15px;
  padding: 7px 16px 7px 28px;
`}
`;

const SearchWrapper = styled.div`
display: flex;
width: 24px;
height: 24px;
position: absolute;
left: 22px;
top: 18px;
`;

const CenterDiv = styled.div`
  width: 50%;
  
`;

const RHSTitlediv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;


const BreadCrumbDiv=styled.div`
margin-bottom: 10px;
color: #4A4A4A;
font-size: 16px;
font-weight: 500;
height: auto;
line-height: 24px;
${media.small`
    font-size: 10px;
  `}
`;

const MobileHeaderDiv=styled.div`
width:70%;
display:flex;
flex-direction:row;
justify-content:space-between;
${media.small`
width:100%;
  `}
`;