import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import {
  WEBFORM_TOKEN,
  HOME_URL,
  SIGNIN_URL,
  TAG_ENDPOINT,
  THESIS_FILTER_ENDPOINT,
} from "../../constants/constants";
import MultiSelect from "../../components/MultiSelect";
import SearchIcon from "../../components/svg/SearchIcon";
import { useNavigate } from "react-router-dom";
import StyledTable from "../../components/StyledTable";
import CompanyTable from "./CompanyTable";
// import CompanyListView from "./CompanyListView";
// import CompanyGridView from "./CompanyGridView";
import Loader from "../../components/Loader";
import axios from "axios";
import moment from "moment";
import MasterTable from "../../components/MasterTable";
import ExternalLinkIcon from "../../components/svg/ExternalLinkIcon";

const CompanyListingOld = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  // API whole data
  const [clientId, setClientId] = useState(
    Number(window.localStorage.getItem("clientID")) || null
  );
  const [allCompanies, setAllCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);

  const [isThesisLoaded, setThesisLoaded] = useState(false);
  const IndustryDict=JSON.parse(window.localStorage.getItem('IndustryDict'))

  const getNameHeader = (count) => {
    if (count <= 1) {
      return (
        <CountTextWrap>
          <div>Name</div>
          <CountText>({count} Result)</CountText>
        </CountTextWrap>
      );
    }
    return (
      <CountTextWrap>
        <div>Name</div>
        <CountText>({count} Results)</CountText>
      </CountTextWrap>
    );
  };

  const [columnsData, setColumnsData] = useState([
    {
      key: "player",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
    },
    // {
    //   key: "sector",
    //   value: "Sector",
    //   sorting: false,
    //   range_filter: false,
    // },
  ]);
  const [isColumnsLoaded, setColumnsLoaded] = useState(false);
  const [styledRowsData, setStyledRowsData] = useState([]);

  const [compSearchString, setCompSearchString] = useState(
    useQuery().get("company") || ""
  );

  const [indReportMapping, setIndReportMapping] = useState([]);
  const [indWiseCompReport, setIndWiseCompReport] = useState([]);
  const [isReportMappingLoaded, setReportMappingLoaded] = useState(false);

  const [industryIdList, setIndustryIdList] = useState([]);
  const [dataMonth, setDataMonth] = useState(null);

  const [tags, setTags] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [valuationList, setValuationList] = useState([
    {
      id: 1,
      name: "0 - 100 Mn (USD)",
      low: 0,
      high: 100,
      selected: false,
    },
    {
      id: 2,
      name: "100 Mn - 500 Mn (USD)",
      low: 100,
      high: 500,
      selected: false,
    },
    {
      id: 3,
      name: "500 Mn - 2 Bn (USD)",
      low: 500,
      high: 2000,
      selected: false,
    },
    {
      id: 4,
      name: "2 Bn - 5 Bn (USD)",
      low: 2000,
      high: 5000,
      selected: false,
    },
    {
      id: 5,
      name: "5 Bn - 10 Bn (USD)",
      low: 5000,
      high: 10000,
      selected: false,
    },
    {
      id: 6,
      name: "10 Bn + (USD)",
      low: 10000,
      high: 100000000,
      selected: false,
    },
  ]);
  const [sortedParameter, setSortedParameter] = useState(null);
  const [sortedDirection, setSortedDirection] = useState("");

  const navigate = useNavigate();

  const treeziparr = [
    { key: 1, name: "Home" },
    { key: 2, name: "Platform Listing" },
  ];

  let handleClickTree = (index) => {
    if (treeziparr[index].key === 1) {
      navigate(HOME_URL);
    }
  };

  useEffect(()=>{
    if(!window.localStorage.getItem("loginStatus"))
      navigate(SIGNIN_URL)
 
   },[])


  // const onSelectParameter = (selectedParam) => {
  //   setVisibleParameter(selectedParam);
  // };

  const setSelectedIndustry = (selectedIndustry) => {
    setIndustryList(
      industryList.map((ind) => {
        return {
          ...ind,
          selected: selectedIndustry.some(
            (selectedInd) => selectedInd.industry_id === ind.industry_id
          ),
        };
      })
    );
  };

  const setSelectedSeries = (selectedSeriesList) => {
    setSeriesList(
      seriesList.map((series) => {
        return {
          ...series,
          selected: selectedSeriesList.some(
            (selectedSeries) => selectedSeries.id === series.id
          ),
        };
      })
    );
  };

  const setSelectedValuation = (selectedValuationList) => {
    setValuationList(
      valuationList.map((valuation) => {
        return {
          ...valuation,
          selected: selectedValuationList.some(
            (selectedValuation) => selectedValuation.id === valuation.id
          ),
        };
      })
    );
  };

  useEffect(() => {
    axios
      .get(`${TAG_ENDPOINT}/?type=thesis-filter`)
      .then((response) => response.data)
      .then((tagData) => {
        setTags(
          tagData.map((obj) => ({
            ...obj,
            selected: false,
          }))
        );
      })
      .catch((error) => {
        console.error("Error in fetching tags-", error);
      });
  }, []);

  useEffect(() => {
    const url = `${THESIS_FILTER_ENDPOINT}/`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${WEBFORM_TOKEN}`,
    };
    axios
      .get(url, { headers })
      .then((response) => response.data)
      .then((companiesData) => {
        if (!isColumnsLoaded && companiesData.length) {
          let updatedParameters = [
            {
              key: "player",
              value: getNameHeader(companiesData.length),
              sorting: false,
              range_filter: false,
            },
            // {
            //   key: "sector",
            //   value: "Sector",
            //   sorting: false,
            //   range_filter: false,
            // },
          ];
          companiesData[0].param_data.map((param) => {
            if ([7072, 7073, 7080, 7075, 7076].includes(param.id)) {
              updatedParameters.push({
                key: param.id,
                value: (
                  <HeaderWrapper>
                    <HeaderText>{param.name}</HeaderText>
                    {param.unit ? (
                      <HeaderText>({param.unit})</HeaderText>
                    ) : null}
                  </HeaderWrapper>
                ),
                sorting: [7072, 7074, 7075, 7076, 7080].includes(param.id)
                  ? true
                  : false,
                range_filter: false,
              });
            }
          });

          setColumnsData(updatedParameters);
          setColumnsLoaded(true);
        }
        const sortedCompaniesData = companiesData
          .sort((a, b) => {
            if (a.industry_id === 10 && b.industry_id !== 10) {
              return -1;
            } else if (a.industry_id !== 10 && b.industry_id === 10) {
              return 1;
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            if (a.industry_id === 18 && b.industry_id !== 18) {
              return -1;
            } else if (a.industry_id !== 18 && b.industry_id === 18) {
              return 1;
            } else {
              return 0;
            }
          });
        setAllCompanies(sortedCompaniesData);
        setVisibleCompanies(sortedCompaniesData);

        const industry_id_set = new Set();
        let industryDataList = [];
        const series_set = new Set();
        companiesData.forEach((obj) => {
          series_set.add(obj.series);
          if (!industry_id_set.has(obj.industry_id)) {
            industry_id_set.add(obj.industry_id);
            industryDataList.push({
              industry_id: obj.industry_id,
              industry_name: obj.industry_name,
              selected: false,
            });
          }
        });
        const industry_id_list = Array.from(industry_id_set);
        setIndustryIdList(industry_id_list);
        setIndustryList(industryDataList);
        setSeriesList(
          Array.from(series_set)
            .map((series) => ({
              id: series,
              name: series,
              selected: false,
            }))
            .sort((a, b) => {
              const a_name = a.name || "";
              const b_name = b.name || "";
              return a_name.localeCompare(b_name);
            })
        );

        setThesisLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });


      let newIndustryReportMapping = [];
      let newReportTree = JSON.parse(window.localStorage.getItem("newReporTree"));
     if(newReportTree)
        {
      newIndustryReportMapping = getNodesAtLevel(newReportTree, 3);
      setIndReportMapping(newIndustryReportMapping);
  
      // }
      // API CALL
      let newIndustryWiseCompanyReport = {};
      newIndustryWiseCompanyReport =
        groupLevel5NodesByParentLevel3(newReportTree);
  
      setIndWiseCompReport(newIndustryWiseCompanyReport);
      setReportMappingLoaded(true);
        }
  }, []);

  const onTagClick = (tagId) => {
    const updatedTags = tags.map((obj) => {
      return {
        ...obj,
        selected: obj.id === tagId ? !obj.selected : obj.selected,
      };
    });
    const commaSeperatedTagList = updatedTags
      .filter((obj) => obj.selected)
      .map((obj) => obj.id)
      .join(",");
    window.localStorage.setItem("thesis_selected_tags", commaSeperatedTagList);

    setTags(
      tags.map((obj) => {
        return {
          ...obj,
          selected: obj.id === tagId ? !obj.selected : obj.selected,
        };
      })
    );
  };

  useEffect(() => {
    let rowsData = [];
    visibleCompanies.forEach((company) => {
      let cpReportData = null;
      let spReports = company.sector_profile_id
        ? indReportMapping.filter(
            (indReport) => indReport.id === company.sector_profile_id
          )
        : [];
      const spReport = IndustryDict[company.sector_profile_id]|| null
      if (company.industry_id) {
        cpReportData =IndustryDict[company.company_profile_id]|| null
      }
      let rowData = {
        player: {
          value: (
            <div>
              <CpProfileLink
                islink={Boolean(cpReportData) ? "true" : "false"}
                onClick={() => {
                  if (Boolean(cpReportData)) {
                    const url = `/Report3/?val=${cpReportData.report_name}&key=${cpReportData.id}&filter=${cpReportData.filter}&filter_value=${cpReportData.filter_value}`;
                    window.open(url, "_blank");
                  }
                }}
              >
                {company.player_name}
              </CpProfileLink>
              <SpProfileLink
                islink={Boolean(spReport) ? "true" : "false"}
                onClick={() => {
                  if (Boolean(spReport)) {
                    const url = `/Report3/?val=${spReport.report_name}&key=${spReport.id}&filter=${spReport.filter}&filter_value=${spReport.filter_value}`;
                    window.open(url, "_blank");
                  }
                }}
              >
                {company.industry_name}
              </SpProfileLink>
            </div>
          ),
          sortingValue: company.player_name,
          toolTip: null,
        },
        // this will be removed
        sector: {
          value: company.industry_name,
          sortingValue: company.industry_name,
          toolTip: null,
        },
      };

      let marketSize = {
        id: null,
        value: null,
        sortingValue: null,
      };

      company.param_data.forEach((param) => {
        const formattedValue =
          typeof param.value === "number"
            ? Number(param.value.toFixed(2)).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : param.value;
        if (param.id === 7074) {
          // this needs to be updated
          rowData[param.id] = {
            value: formattedValue,

            sortingValue: param.value,
          };
        } else if (param.id === 7072) {
          marketSize.id = param.id;
          marketSize.value = formattedValue;
        } else if (param.id === 7077) {
          marketSize.sortingValue = param.value;
        } else {
          rowData[param.id] = {
            value: formattedValue,
            sortingValue: param.value,
          };
        }
        rowData[marketSize.id] = {
          value: marketSize.value,
          sortingValue: marketSize.sortingValue,
          toolTip: null,
        };
      });
      rowsData.push(rowData);
    });

    setStyledRowsData(
      rowsData.sort((a, b) => {
        const aVal = a[7072]?.sortingValue || 0;
        const bVal = b[7072]?.sortingValue || 0;
        if (typeof aVal === "number" && typeof bVal === "number") {
          return bVal - aVal;
        } else if (typeof aVal === "string" && typeof bVal === "string") {
          return bVal.localeCompare(aVal);
        } else {
          return 0;
        }
      })
      // rowsData.sort((a, b) => {
      //   const aVal = a[sortedParameter]?.sortingValue || 0;
      //   const bVal = b[sortedParameter]?.sortingValue || 0;
      //   if (typeof aVal === "number" && typeof bVal === "number") {
      //     return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
      //   } else if (typeof aVal === "string" && typeof bVal === "string") {
      //     return sortedDirection === "asc"
      //       ? aVal.localeCompare(bVal)
      //       : bVal.localeCompare(aVal);
      //   } else {
      //     return 0;
      //   }
      // })
    );

    setSortedParameter(7072);
    setSortedDirection("desc");
  }, [visibleCompanies, isReportMappingLoaded]);

  const setSortedColumn = (sortedCol) => {
    setSortedParameter(sortedCol);
  };

  useEffect(() => {
    if (sortedParameter) {
      setStyledRowsData(
        [...styledRowsData].sort((a, b) => {
          const aVal = a[sortedParameter]?.sortingValue;
          const bVal = b[sortedParameter]?.sortingValue;
          if (aVal == null && bVal == null) {
            return 0;
          } else if (aVal == null) {
            return 1;
          } else if (bVal == null) {
            return -1;
          }

          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
          } else if (typeof aVal === "string" && typeof bVal === "string") {
            return sortedDirection === "asc"
              ? aVal.localeCompare(bVal)
              : bVal.localeCompare(aVal);
          } else {
            return 0;
          }
        })
      );
    }
  }, [sortedParameter, sortedDirection]);

  const stringContains = (target, query) => {
    const targetString = target.replace(/\s/g, "").toLowerCase();
    const queryString = query.replace(/\s/g, "").toLowerCase();
    return targetString.includes(queryString);
  };

  const tagsCompare = (comTags, selectedTag) => {
    // return comTags.some((obj1) =>
    //   selectedTag.some((obj2) => obj1.id === obj2.id)
    // );
    return selectedTag.every((obj2) =>
      comTags.some((obj1) => obj1.id === obj2.id)
    );
  };

  useEffect(() => {
    let selectedTags = tags.filter((tag) => tag.selected) || tags;
    const selectedIndustryIdList = industryList
      .filter((ind) => ind.selected)
      .map((ind) => ind.industry_id);

    const selectedSeriesIdList = seriesList
      .filter((series) => series.selected)
      .map((series) => series.id);

    const selectedValuationList = valuationList.filter(
      (series) => series.selected
    );

    const newFilteredCompanies = allCompanies
      .filter((comp) => stringContains(comp.player_name, compSearchString))
      .filter((comp) =>
        selectedTags.length === 0 ? true : tagsCompare(comp.tags, selectedTags)
      )
      .filter((comp) =>
        selectedIndustryIdList.length === 0
          ? true
          : selectedIndustryIdList.includes(comp.industry_id)
      )
      .filter((comp) =>
        selectedSeriesIdList.length === 0
          ? true
          : selectedSeriesIdList.includes(comp.series)
      )
      .filter((comp) => {
        if (selectedValuationList.length) {
          if (comp.valuation) {
            if (
              selectedValuationList.some(
                (range) =>
                  comp.valuation >= range.low && comp.valuation <= range.high
              )
            )
              return true;
            return false;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    setVisibleCompanies(newFilteredCompanies);
    setColumnsData(
      columnsData.map((col) => {
        return {
          ...col,
          value:
            col.key === "player"
              ? getNameHeader(newFilteredCompanies.length)
              : col.value,
        };
      })
    );
  }, [
    tags,
    compSearchString,
    industryList,
    seriesList,
    valuationList,
    allCompanies,
  ]);

  // #//////////
  // ###########
  // #//////////
  // ###########
  // #//////////
  // ###########
  // Fetching CP powerbi profiles
  function getNodesAtLevel(data, level, result = []) {
    data.forEach(node => {
        if (node.level === level) {
            result.push(node);
        }
        if (node.children && node.children.length > 0) {
            getNodesAtLevel(node.children, level, result);
        }
    });
    return result;
}

function groupLevel5NodesByParentLevel3(data) {
  const result = {};

  function traverse(nodes) {
    nodes.forEach(node => {
      if (node.level === 3 && node.children && node.children.length > 0) {
        node.children.forEach(child => {
          if (child.level === 4 && child.children && child.children.length > 0) {
            if (!result[node.industry_id]) {
              result[node.industry_id] = [];
            }
            result[node.industry_id].push(...child.children.map(level5Node => ({
              ...level5Node,
              sectorNode: node
            })));
          }
        });
      }
      if (node.children && node.children.length > 0) {
        traverse(node.children);
      }
    });
  }

  traverse(data);

  // Convert result object to array of arrays
  const finalResult = Object.values(result);

  return finalResult;
}

//   useEffect(() => {

//     let newIndustryReportMapping = [];
//     let newReportTree=JSON.parse(window.localStorage.getItem('newReporTree'))
//         newIndustryReportMapping = getNodesAtLevel(newReportTree, 3)
//         setIndReportMapping(newIndustryReportMapping);

//     // }
//     // API CALL
//     let newIndustryWiseCompanyReport = {}; 
//       newIndustryWiseCompanyReport = groupLevel5NodesByParentLevel3(newReportTree);

//     setIndWiseCompReport(newIndustryWiseCompanyReport);
//     setReportMappingLoaded(true);
//   }, []);

  return (
    <PageWrapper>
      <Header />
      <div>
        <div style={{ backgroundColor: "#FFFFFF", minHeight: "10vh" }}>
          <Header1>
            <LHSTitlediv>
              <div>Platform Listing</div>
              <div
                style={{
                  marginBottom: "10px",
                  color: "#4A4A4A",
                  fontSize: "16px",
                  fontWeight: "500",
                  height: "auto",
                  lineHeight: "24px",
                }}
              >
                <>
                  {treeziparr?.map((obj, i) => (
                    <BreadCrumbSpan
                      onClick={(e) => {
                        handleClickTree(i, obj.key);
                      }}
                      key={i}
                    >
                      {obj.name} /{" "}
                    </BreadCrumbSpan>
                  ))}
                </>
              </div>
            </LHSTitlediv>
            <CenterDiv>
              <CompanyInputWrapper>
                <CompanyInput
                  placeholder="Search more than 400+ companies..."
                  type="search"
                  value={compSearchString}
                  onChange={(e) => {
                    setCompSearchString(e.target.value);
                  }}
                ></CompanyInput>
                <SearchWrapper>
                  <SearchIcon />
                </SearchWrapper>
              </CompanyInputWrapper>
            </CenterDiv>
            <RHSTitlediv>
              <NavigationRow onClick={() => navigate("/portfolio")}>
                <LinkIcon>
                  <ExternalLinkIcon />
                </LinkIcon>
                <LinkText>View My Portfolio</LinkText>
              </NavigationRow>
            </RHSTitlediv>
          </Header1>
        </div>
        {/* <CompanySearch>
          <CompanyInputWrapper>
            <CompanyInput
              placeholder="Search Company Name"
              type="search"
              value={compSearchString}
              onChange={(e) => {
                setCompSearchString(e.target.value);
              }}
            ></CompanyInput>
            <SearchWrapper>
              <SearchIcon />
            </SearchWrapper>
          </CompanyInputWrapper>
        </CompanySearch>
        <TagsWrapper>
          {tags.map((tag) => (
            <TagDiv
              id={tag.id}
              key={tag.id}
              selected={tag.selected}
              onClick={() => {
                onTagClick(tag.id);
              }}
            >
              {tag.name}
            </TagDiv>
          ))}
        </TagsWrapper> */}
        <GridWraper>
          {isThesisLoaded ? (
            <>
              <ShadowWrap>
                <CompanyTable
                  compSearchString={compSearchString}
                  setCompSearchString={setCompSearchString}
                  tags={tags}
                  onTagClick={onTagClick}
                  allParameters={columnsData}
                  rowsData={styledRowsData}
                  dataMonth={dataMonth}
                  industryList={industryList}
                  setSelectedIndustry={setSelectedIndustry}
                  seriesList={seriesList}
                  setSelectedSeries={setSelectedSeries}
                  valuationList={valuationList}
                  setSelectedValuation={setSelectedValuation}
                  sortedParameter={sortedParameter}
                  setSortedParameter={setSortedColumn}
                  sortedDirection={sortedDirection}
                  setSortedDirection={setSortedDirection}
                />
              </ShadowWrap>
            </>
          ) : (
            <Loader />
          )}
        </GridWraper>
      </div>
    </PageWrapper>
  );
};

export default CompanyListingOld;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7fcff;
  min-height: 100vh;
`;

const CompanySearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 30px;
  align-items: center;
  background-color: #ffffff;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  position: relative;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 22px;
  top: 18px;
`;

const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 20% 24px;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: center;
`;

const TagDiv = styled.div`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 10px 6px 10px;
  background-color: ${(props) => (props.selected ? "#0099FF" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#262e40")};
  margin: 6px 6px;
  border-radius: 24px;
  cursor: pointer;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
`;

const ShadowWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const LHSTitlediv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 25%;
  font-weight: 500;
  font-size: 30px;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  font-weight: 500;
  font-size: 30px;
`;

const RHSTitlediv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 25%;
  gap: 10px;
`;

const NavigationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0px 20px;
  height: 55px;
  border-radius: 32px;
  background: #0099ff;
  color: #ffffff;
  cursor: pointer;
`;

const LinkText = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

const LinkIcon = styled.div`
  color: #0099ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  padding-right: 8px;
`;

const CpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    font-weight: ${(props) => (props.islink === "true" ? 500 : 400)};
  }
`;

const SpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#A0A6A9" : "#A0A6A9")};
  text-decoration: ${(props) =>
    props.islink === "true" ? "underline" : "none"};
  font-size: 12px;
  font-style: italic;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    font-weight: ${(props) => (props.islink === "true" ? 500 : 400)};
    color: ${(props) => (props.islink === "true" ? "#A0A0A0" : "#A0A6A9")};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 700px;
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
`;
