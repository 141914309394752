import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import {
  WEBFORM_TOKEN,
  HOME_URL,
  SIGNIN_URL,
  TAG_ENDPOINT,
  PARAMETER_GROUP_ENDPOINT,
} from "../../constants/constants.js";
import MultiSelect from "../../components/MultiSelect/index.js";
import SearchIcon from "../../components/svg/SearchIcon.js";
import { useNavigate } from "react-router-dom";
import StyledTable from "../../components/StyledTable/index.js";
import AdminTable from "./AdminTable/";
// import CompanyListView from "./CompanyListView";
// import CompanyGridView from "./CompanyGridView";
import Loader from "../../components/Loader/index.js";
import axios from "axios";
import moment from "moment";
import MasterTable from "../../components/MasterTable/index.js";
import ExternalLinkIcon from "../../components/svg/ExternalLinkIcon.js";
import { media } from "../../utils/media.js";

const AdminConsole = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  // API whole data
  const [clientId, setClientId] = useState(
    Number(window.localStorage.getItem("clientID")) || null
  );
  const [allCompanies, setAllCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);

  const [isDataLoaded, setDataLoaded] = useState(false);
  const IndustryDict=JSON.parse(window.localStorage.getItem('IndustryDict'))

  const getNameHeader = (count) => {
    if (count <= 1) {
      return (
        <CountTextWrap>
          <div>Name</div>
          <CountText>({count} Result)</CountText>
        </CountTextWrap>
      );
    }
    return (
      <CountTextWrap>
        <div>Name</div>
        <CountText>({count} Results)</CountText>
      </CountTextWrap>
    );
  };

  const [columnsData, setColumnsData] = useState([
    {
      key: "player",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: null,
    },
  ]);
  const [isColumnsLoaded, setColumnsLoaded] = useState(false);
  const [parameterRange, setParameterRange] = useState({});
  const [parameterValueFilter, setParameterValueFilter] = useState({});
  const [styledRowsData, setStyledRowsData] = useState([]);

  const [compSearchString, setCompSearchString] = useState(
    useQuery().get("company") || ""
  );

  const [indReportMapping, setIndReportMapping] = useState([]);
  const [indWiseCompReport, setIndWiseCompReport] = useState([]);
  const [isReportMappingLoaded, setReportMappingLoaded] = useState(false);

  const [industryIdList, setIndustryIdList] = useState([]);
  const [dataMonth, setDataMonth] = useState(null);

  const [tags, setTags] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [valuationList, setValuationList] = useState([
    {
      id: 1,
      name: "0 - 100 Mn (USD)",
      low: 0,
      high: 100,
      selected: false,
    },
    {
      id: 2,
      name: "100 Mn - 500 Mn (USD)",
      low: 100,
      high: 500,
      selected: false,
    },
    {
      id: 3,
      name: "500 Mn - 2 Bn (USD)",
      low: 500,
      high: 2000,
      selected: false,
    },
    {
      id: 4,
      name: "2 Bn - 5 Bn (USD)",
      low: 2000,
      high: 5000,
      selected: false,
    },
    {
      id: 5,
      name: "5 Bn - 10 Bn (USD)",
      low: 5000,
      high: 10000,
      selected: false,
    },
    {
      id: 6,
      name: "10 Bn + (USD)",
      low: 10000,
      high: 100000000,
      selected: false,
    },
  ]);
  const [sortedParameter, setSortedParameter] = useState(null);
  const [sortedDirection, setSortedDirection] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (clientId !== 1 && clientId !== 53) {
      navigate(HOME_URL);
    }
  }, []);

  const treeziparr = [
    { key: 1, name: "Home" },
    { key: 2, name: "Admin Console" },
  ];

  let handleClickTree = (index) => {
    if (treeziparr[index].key === 1) {
      navigate(HOME_URL);
    }
  };

  useEffect(()=>{
    if(!window.localStorage.getItem("loginStatus"))
      navigate(SIGNIN_URL)
 
   },[])

  const setSelectedIndustry = (selectedIndustry) => {
    setIndustryList(
      industryList.map((ind) => {
        return {
          ...ind,
          selected: selectedIndustry.some(
            (selectedInd) => selectedInd.industry_id === ind.industry_id
          ),
        };
      })
    );
  };

  const setSelectedValuation = (selectedValuationList) => {
    setValuationList(
      valuationList.map((valuation) => {
        return {
          ...valuation,
          selected: selectedValuationList.some(
            (selectedValuation) => selectedValuation.id === valuation.id
          ),
        };
      })
    );
  };

  useEffect(() => {
    axios
      .get(`${TAG_ENDPOINT}/?type=thesis-filter`)
      .then((response) => response.data)
      .then((tagData) => {
        setTags(
          tagData.map((obj) => ({
            ...obj,
            selected: false,
          }))
        );
      })
      .catch((error) => {
        console.error("Error in fetching tags-", error);
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${WEBFORM_TOKEN}`,
    };
    axios
      .post(
        PARAMETER_GROUP_ENDPOINT,
        {
          table_type: "admin_console",
          industry_id: 40,
          profile: "sector_profile",
          data_category: "overall",
          data_frequency: "mixed",
          end_date: moment(
            moment().startOf("month").subtract(2, "month")
          ).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          if (!isColumnsLoaded) {
            let updatedParameters = [
              {
                key: "player",
                value: getNameHeader(responseData.player_list.length),
                sorting: false,
                range_filter: false,
                value_filter: false,
                min_width: null,
              },
            ];
            response.data.parameter_list
              .sort((a, b) => a.sequence - b.sequence)
              .map((parObj) => {
                updatedParameters.push({
                  key: parObj.parameter,
                  value: (
                    <HeaderWrapper>
                      <HeaderText>{parObj.name}</HeaderText>
                      {parObj.unit ? (
                        <HeaderText>({parObj.unit})</HeaderText>
                      ) : null}
                    </HeaderWrapper>
                  ),
                  sorting: parObj.sorting,
                  range_filter: parObj.range_filter,
                  range_filter_data: parObj.range_filter_data,
                  value_filter: parObj.value_filter,
                  min_width: parObj.col_width,
                });
              });
            setColumnsData(updatedParameters);
            setColumnsLoaded(true);
          }
          const industry_id_set = new Set();
          let industryDataList = [];
          let companiesData = responseData.player_list.map((comp) => {
            if (!industry_id_set.has(comp.industry_id)) {
              industry_id_set.add(comp.industry_id);
              industryDataList.push({
                industry_id: comp.industry_id,
                industry_name: comp.industry_name,
                selected: false,
              });
            }
            return {
              ...comp,
              param_data: responseData.players_data[comp.player_id],
            };
          });
          companiesData = companiesData
            .sort((a, b) => {
              if (a.industry_id === 10 && b.industry_id !== 10) {
                return -1;
              } else if (a.industry_id !== 10 && b.industry_id === 10) {
                return 1;
              } else {
                return 0;
              }
            })
            .sort((a, b) => {
              if (a.industry_id === 18 && b.industry_id !== 18) {
                return -1;
              } else if (a.industry_id !== 18 && b.industry_id === 18) {
                return 1;
              } else {
                return 0;
              }
            });
          setAllCompanies(companiesData);
          setVisibleCompanies(companiesData);

          const industry_id_list = Array.from(industry_id_set);
          setIndustryIdList(industry_id_list);
          setIndustryList(industryDataList);

          setDataLoaded(true);
        } else {
          console.error(
            "Error in fetching parameter group in admin console ",
            response.error.message
          );
          setDataLoaded(true);
        }
      })
      .catch((error) => {
        console.error(
          "Error in fetching parameter group for admin console -",
          error
        );
        setDataLoaded(true);
      });
      let newIndustryReportMapping = [];
      let newReportTree = JSON.parse(window.localStorage.getItem("newReporTree"));
     
      newIndustryReportMapping = getNodesAtLevel(newReportTree, 3);
      setIndReportMapping(newIndustryReportMapping);
  
      // }
      // API CALL
      let newIndustryWiseCompanyReport = {};
      newIndustryWiseCompanyReport =
        groupLevel5NodesByParentLevel3(newReportTree);
  
      setIndWiseCompReport(newIndustryWiseCompanyReport);
      setReportMappingLoaded(true);
  

      
  }, []);

  useEffect(() => {
    if (isColumnsLoaded) {
      const ColumnsValueFilter = {};
      const valueFilterColumns = columnsData.filter((col) => col.value_filter);
      allCompanies.forEach((comp) => {
        valueFilterColumns.forEach((col) => {
          if (!ColumnsValueFilter[col.key]) {
            ColumnsValueFilter[col.key] = new Set();
          }
          if (comp.param_data[col.key].sortingValue) {
            ColumnsValueFilter[col.key].add(
              comp.param_data[col.key].sortingValue
            );
          }
        });
      });
      valueFilterColumns.forEach((col) => {
        ColumnsValueFilter[col.key] = Array.from(
          ColumnsValueFilter[col.key] || []
        ).map((name, idx) => ({
          id: idx,
          name: name,
          selected: false,
        }));
      });
      setParameterValueFilter(ColumnsValueFilter);
    }
  }, [allCompanies, isColumnsLoaded]);

  const onTagClick = (tagId) => {
    const updatedTags = tags.map((obj) => {
      return {
        ...obj,
        selected: obj.id === tagId ? !obj.selected : obj.selected,
      };
    });
    const commaSeperatedTagList = updatedTags
      .filter((obj) => obj.selected)
      .map((obj) => obj.id)
      .join(",");
    window.localStorage.setItem("thesis_selected_tags", commaSeperatedTagList);

    setTags(
      tags.map((obj) => {
        return {
          ...obj,
          selected: obj.id === tagId ? !obj.selected : obj.selected,
        };
      })
    );
  };

  const onRangeFilterApply = (parameter_id, selectedRange) => {
    setParameterRange({ ...parameterRange, [parameter_id]: selectedRange });
  };

  const onValueFilterApply = (parameter_id, valueFilter) => {
    setParameterValueFilter({
      ...parameterValueFilter,
      [parameter_id]: valueFilter,
    });
  };

  useEffect(() => {
    let rowsData = [];
    visibleCompanies.forEach((company) => {
      let cpReportData = null;
      let spReports = company.sector_profile_id
        ? indReportMapping.filter(
            (indReport) => indReport.id === company.sector_profile_id
          )
        : [];
      const spReport = IndustryDict[company.sector_profile_id]|| null
      if (company.industry_id) {
        cpReportData =IndustryDict[company.company_profile_id]|| null
      }

      let rowData = {
        player: {
          value: (
            <div>
              <CpProfileLink
                islink={Boolean(cpReportData) ? "true" : "false"}
                onClick={() => {
                  if (Boolean(cpReportData)) {
                    // navigate(
                    //   `/Report3/?val=${cpReportData.label}&key=${cpReportData.key}&filter=${cpReportData.filter}&filter_value=${cpReportData.filter_value}`
                    // );
                    const url = `/Report3/?val=${cpReportData.report_name}&key=${cpReportData.id}&filter=${cpReportData.filter}&filter_value=${cpReportData.filter_value}`;
                    window.open(url, "_blank");
                  }
                }}
              >
                {company.player_name}
              </CpProfileLink>
              <SpProfileLink
                islink={Boolean(spReport) ? "true" : "false"}
                onClick={() => {
                  if (Boolean(spReport)) {
                    const url = `/Report3/?val=${spReport.report_name}&key=${spReport.id}&filter=${spReport.filter}&filter_value=${spReport.filter_value}`;
                    window.open(url, "_blank");
                  }
                }}
              >
                {company.industry_name}
              </SpProfileLink>
            </div>
          ),
          sortingValue: company.player_name,
          toolTip: null,
        },
        // this will be removed
        sector: {
          value: company.industry_name,
          sortingValue: company.industry_name,
          toolTip: null,
        },
      };
      columnsData.forEach((col) => {
        if (col.key !== "player") {
          const param = company.param_data[col.key];
          const formattedValue =
            typeof param.value === "number"
              ? Number(param.value.toFixed(2)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : param.value;

          rowData[col.key] = {
            value: formattedValue,
            sortingValue: param.sortingValue,
          };
        }
      });
      rowsData.push(rowData);
    });

    rowsData = rowsData
      .sort((a, b) => {
        const aVal = a[sortedParameter]?.sortingValue || 0;
        const bVal = b[sortedParameter]?.sortingValue || 0;
        if (typeof aVal === "number" && typeof bVal === "number") {
          return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
        } else if (typeof aVal === "string" && typeof bVal === "string") {
          return sortedDirection === "asc"
            ? aVal.localeCompare(bVal)
            : bVal.localeCompare(aVal);
        } else {
          return 0;
        }
      })
      .filter((rows) => {
        let allParameterInRange = true;
        for (const key in parameterRange) {
          if (parameterRange[key] && parameterRange[key].length) {
            if (!rows[key]) {
              continue;
            } else {
              if (
                !parameterRange[key].some(
                  (range) =>
                    rows[key].sortingValue &&
                    rows[key].sortingValue >= range.low &&
                    rows[key].sortingValue <= range.high
                )
              ) {
                allParameterInRange = false;
              }
            }
          }
        }
        for (const key in parameterValueFilter) {
          const selectedValueFilter = parameterValueFilter[key].filter(
            (opt) => opt.selected
          );
          if (selectedValueFilter.length) {
            if (!rows[key]) {
              continue;
            } else {
              if (
                !selectedValueFilter.some(
                  (opt) => rows[key].sortingValue === opt.name
                )
              ) {
                allParameterInRange = false;
              }
            }
          }
        }
        return allParameterInRange;
      });
    setStyledRowsData(rowsData);
    setColumnsData(
      columnsData.map((col) => {
        return {
          ...col,
          value:
            col.key === "player" ? getNameHeader(rowsData.length) : col.value,
        };
      })
    );
  }, [
    visibleCompanies,
    parameterRange,
    parameterValueFilter,
    isReportMappingLoaded,
  ]);

  const setSortedColumn = (sortedCol) => {
    setSortedParameter(sortedCol);
  };

  useEffect(() => {
    if (sortedParameter) {
      setStyledRowsData(
        [...styledRowsData].sort((a, b) => {
          const aVal = a[sortedParameter]?.sortingValue;
          const bVal = b[sortedParameter]?.sortingValue;
          if (aVal == null && bVal == null) {
            return 0;
          } else if (aVal == null) {
            return 1;
          } else if (bVal == null) {
            return -1;
          }

          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
          } else if (typeof aVal === "string" && typeof bVal === "string") {
            return sortedDirection === "asc"
              ? aVal.localeCompare(bVal)
              : bVal.localeCompare(aVal);
          } else {
            return 0;
          }
        })
      );
    }
  }, [sortedParameter, sortedDirection]);

  const stringContains = (target, query) => {
    const targetString = target.replace(/\s/g, "").toLowerCase();
    const queryString = query.replace(/\s/g, "").toLowerCase();
    return targetString.includes(queryString);
  };

  const tagsCompare = (comTags, selectedTag) => {
    // return comTags.some((obj1) =>
    //   selectedTag.some((obj2) => obj1.id === obj2.id)
    // );
    return selectedTag.every((obj2) =>
      comTags.some((obj1) => obj1.id === obj2.id)
    );
  };

  useEffect(() => {
    let selectedTags = tags.filter((tag) => tag.selected) || tags;
    const selectedIndustryIdList = industryList
      .filter((ind) => ind.selected)
      .map((ind) => ind.industry_id);

    const selectedValuationList = valuationList.filter(
      (series) => series.selected
    );

    const newFilteredCompanies = allCompanies
      .filter((comp) => stringContains(comp.player_name, compSearchString))
      .filter((comp) =>
        selectedTags.length === 0 ? true : tagsCompare(comp.tags, selectedTags)
      )
      .filter((comp) =>
        selectedIndustryIdList.length === 0
          ? true
          : selectedIndustryIdList.includes(comp.industry_id)
      )
      .filter((comp) => {
        if (selectedValuationList.length) {
          if (comp.valuation) {
            if (
              selectedValuationList.some(
                (range) =>
                  comp.valuation >= range.low && comp.valuation <= range.high
              )
            )
              return true;
            return false;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    setVisibleCompanies(newFilteredCompanies);
    setColumnsData(
      columnsData.map((col) => {
        return {
          ...col,
          value:
            col.key === "player"
              ? getNameHeader(newFilteredCompanies.length)
              : col.value,
        };
      })
    );
  }, [tags, compSearchString, industryList, valuationList, allCompanies]);

  // #//////////
  // ###########
  // #//////////
  // ###########
  // #//////////
  // ###########
  // Fetching CP powerbi profiles
  function getNodesAtLevel(data, level, result = []) {
    data.forEach((node) => {
      if (node.level === level) {
        result.push(node);
      }
      if (node.children && node.children.length > 0) {
        getNodesAtLevel(node.children, level, result);
      }
    });
    return result;
  }

  function groupLevel5NodesByParentLevel3(data) {
    const result = {};

    function traverse(nodes) {
      nodes.forEach((node) => {
        if (node.level === 3 && node.children && node.children.length > 0) {
          node.children.forEach((child) => {
            if (
              child.level === 4 &&
              child.children &&
              child.children.length > 0
            ) {
              if (!result[node.industry_id]) {
                result[node.industry_id] = [];
              }
              result[node.industry_id].push(
                ...child.children.map((level5Node) => ({
                  ...level5Node,
                  sectorNode: node,
                }))
              );
            }
          });
        }
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      });
    }

    traverse(data);

    return result;
  }

  useEffect(() => {
    // const savedTreeData = JSON.parse(localStorage.getItem("windowTreeData"));

    // const sectorCategoryNodes = savedTreeData?.[0]?.nodes;
    // let newIndustryReportMapping1 = [];
    //   sectorCategoryNodes.forEach((category) => {
    //     category.nodes?.forEach((node) => {
    //       if (node.industry_id) {
    //         newIndustryReportMapping1.push(node);
    //       }
    //     });
    //   });

    // let newIndustryWiseCompanyReport1 = {};

    // newIndustryReportMapping1.forEach((indReportData) => {
    //   fetch(
    //     `${process.env.REACT_APP_API_ENDPOINT}/nodechildren/?key=${indReportData.key}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res.length >= 1) {
    //         let oldReport =
    //           newIndustryWiseCompanyReport1[indReportData.industry_id] || [];
    //         const newRes = res.map((obj) => ({
    //           ...obj,
    //           sectorNode: indReportData,
    //         }));
    //         let allReport = [...oldReport, ...newRes];
    //         newIndustryWiseCompanyReport1[indReportData.industry_id] = allReport;
    //       }
    //     });
    // });

    // console.log("old",newIndustryWiseCompanyReport1)

    let newIndustryReportMapping = [];
    let newReportTree = JSON.parse(window.localStorage.getItem("newReporTree"));
    if(newReportTree)
      {
    newIndustryReportMapping = getNodesAtLevel(newReportTree, 3);
    setIndReportMapping(newIndustryReportMapping);

    // }
    // API CALL
    let newIndustryWiseCompanyReport = {};
    newIndustryWiseCompanyReport =
      groupLevel5NodesByParentLevel3(newReportTree);

    setIndWiseCompReport(newIndustryWiseCompanyReport);
    setReportMappingLoaded(true);
      }
  }, []);

  return (
    <PageWrapper>
      <Header />
      <div>
        <div style={{ backgroundColor: "#FFFFFF", minHeight: "10vh" }}>
          <Header1>
            <LHSTitlediv>
              <AdminConsoleDiv>Admin Console</AdminConsoleDiv>
              <BreadCrumbDiv
              >
                <>
                  {treeziparr?.map((obj, i) => (
                    <BreadCrumbSpan
                      onClick={(e) => {
                        handleClickTree(i, obj.key);
                      }}
                      key={i}
                    >
                      {obj.name} /{" "}
                    </BreadCrumbSpan>
                  ))}
                </>
              </BreadCrumbDiv>
            </LHSTitlediv>
            <CenterDiv>
              <CompanyInputWrapper>
                <CompanyInput
                  placeholder="Search more than 400+ companies..."
                  type="search"
                  value={compSearchString}
                  onChange={(e) => {
                    setCompSearchString(e.target.value);
                  }}
                ></CompanyInput>
                {/* <SearchWrapper>
                  <SearchIcon />
                </SearchWrapper> */}
              </CompanyInputWrapper>
            </CenterDiv>
          </Header1>
        </div>
        <GridWraper>
          {isDataLoaded ? (
            <>
              <ShadowWrap>
                <AdminTable
                  compSearchString={compSearchString}
                  setCompSearchString={setCompSearchString}
                  tags={tags}
                  onTagClick={onTagClick}
                  allParameters={columnsData}
                  rowsData={styledRowsData}
                  dataMonth={dataMonth}
                  industryList={industryList}
                  setSelectedIndustry={setSelectedIndustry}
                  valuationList={valuationList}
                  setSelectedValuation={setSelectedValuation}
                  sortedParameter={sortedParameter}
                  setSortedParameter={setSortedColumn}
                  sortedDirection={sortedDirection}
                  setSortedDirection={setSortedDirection}
                  onRangeFilterApply={onRangeFilterApply}
                  filteredRange={parameterRange}
                  valueFilterData={parameterValueFilter}
                  onValueFilterApply={onValueFilterApply}
                />
              </ShadowWrap>
            </>
          ) : (
            <Loader />
          )}
        </GridWraper>
      </div>
    </PageWrapper>
  );
};

export default AdminConsole;

const BreadCrumbDiv=styled.div`
    margin-bottom: 10px;
  color: #4A4A4A;
font-size: 16px;
font-weight: 500;
  height:auto;
  line-height:24px;
  ${media.small`
    font-size: 10px;

    `}
`
const AdminConsoleDiv=styled.div`
font-size: 30px;
white-space:nowrap;
${media.small`
  font-size: 20px;
  `}
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7fcff;
  min-height: 100vh;
`;

const CompanySearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 30px;
  align-items: center;
  background-color: #ffffff;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  ${media.small`
      height: 30px;

    `}
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
    ${media.small`
  padding: 7px 16px 7px 28px;
    font-size: 15px;
     ::placeholder {
    font-size: 9px;
  }
      `}
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 22px;
  top: 18px;
`;

const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 20% 24px;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: center;
`;

const TagDiv = styled.div`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 10px 6px 10px;
  background-color: ${(props) => (props.selected ? "#0099FF" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#262e40")};
  margin: 6px 6px;
  border-radius: 24px;
  cursor: pointer;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
`;

const ShadowWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const LHSTitlediv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-weight: 500;
  font-size: 30px;
  ${media.small`
      font-size: 20px;
    `}
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  font-size: 30px;
`;

const RHSTitlediv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 25%;
  gap: 10px;
`;

const NavigationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0px 20px;
  height: 55px;
  border-radius: 32px;
  background: #0099ff;
  color: #ffffff;
  cursor: pointer;
`;

const LinkText = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

const LinkIcon = styled.div`
  color: #0099ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  padding-right: 8px;
`;

const CpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    font-weight: ${(props) => (props.islink === "true" ? 500 : 400)};
  }
`;

const SpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#A0A6A9" : "#A0A6A9")};
  text-decoration: ${(props) =>
    props.islink === "true" ? "underline" : "none"};
  font-size: 12px;
  font-style: italic;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    font-weight: ${(props) => (props.islink === "true" ? 500 : 400)};
    color: ${(props) => (props.islink === "true" ? "#A0A0A0" : "#A0A6A9")};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 700px;
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
`;
